import React from 'react';
import { arrayOf, func, node, oneOf, shape, string } from 'prop-types';

// Block components
import BlockDefault from './BlockDefault';
import BlockSocialMediaLink from './BlockSocialMediaLink';
import CustomDefaultBlock from './BlockDefault/CustomDefaultBlock';
import CustomFooterBlock from './BlockFooter/CustomFooterBlock';

const defaultBlockComponents = {
  defaultBlock: { component: BlockDefault },
  footerBlock: { component: CustomFooterBlock },
  socialMediaLink: { component: BlockSocialMediaLink },
};

const sectionIdsWithCustomDefaultBlock = ['section-2', 'section-4', 'section-5'];

const getCustomDefaultBlockComponents = (pageType, sectionId) => {
  if (pageType === 'landing') {
    if (sectionIdsWithCustomDefaultBlock.includes(sectionId)) {
      return { defaultBlock: { component: CustomDefaultBlock } };
    }
  }
  return [];
};

const BlockBuilder = props => {
  const { blocks, sectionId, options, pageType, ...otherProps } = props;

  const { blockComponents, fieldComponents } = options || {};
  const blockOptionsMaybe = fieldComponents ? { options: { fieldComponents } } : {};

  if (!blocks || blocks.length === 0) {
    return null;
  }
  const components = {
    ...defaultBlockComponents,
    ...blockComponents,
    ...getCustomDefaultBlockComponents(pageType, sectionId),
  };

  const isStylistSection = sectionId === 'section-4' && pageType === 'landing';
  const isExplanationSection = sectionId === 'section-2' && pageType === 'landing';
  const isTestimonialSection = sectionId === 'section-5' && pageType === 'landing';

  return (
    <>
      {blocks.map((block, index) => {
        const config = components[block.blockType];
        const Block = config?.component;
        const blockId = block.blockId || `${sectionId}-block-${index + 1}`;

        if (Block) {
          return (
            <Block
              key={blockId}
              {...block}
              blockId={blockId}
              isTestimonialSection={isTestimonialSection}
              isStylistSection={isStylistSection}
              isExplanationSection={isExplanationSection}
              isLastBlock={index === blocks.length - 1}
              {...blockOptionsMaybe}
              {...otherProps}
            />
          );
        } else {
          console.warn(`Unknown block type (${block.blockType}) detected inside (${sectionId}).`);
          return null;
        }
      })}
    </>
  );
};

const propTypeBlock = shape({
  blockId: string,
  blockName: string,
  blockType: oneOf(['defaultBlock', 'footerBlock', 'socialMediaLink']).isRequired,
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
});

BlockBuilder.defaultProps = {
  blocks: [],
  options: null,
  responsiveImageSizes: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
};

BlockBuilder.propTypes = {
  blocks: arrayOf(propTypeBlock),
  options: propTypeOption,
  responsiveImageSizes: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
};

export default BlockBuilder;
