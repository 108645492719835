import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import StylistImage1 from '../../../../assets/stylist1.jpg';
import StylistImage2 from '../../../../assets/stylist2.jpg';

import css from './CustomDefaultBlock.module.css';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    isStylistSection,
    isExplanationSection,
    isTestimonialSection,
    isLastBlock,
  } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    css.blockContainer,
    isTestimonialSection && css.testimonialBlockWrapper
  );
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {isStylistSection && (
        <div className={css.extraImages}>
          <img src={StylistImage1} alt="Stylist image 1" />
          <img src={StylistImage2} alt="Stylist image 2" />
        </div>
      )}
      {isExplanationSection && !isLastBlock && <span className={css.arrow}></span>}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
