import React, { useState } from 'react';
import css from './SectionFooter.module.css';
import { Text } from '../../Primitives/Text';

const NewsletterSubscribe = () => {
  const [data, setData] = useState({ 'contact-email': '' });

  const handleDataChange = e => {
    const { name, value } = e.target;
    const updatedData = {
      ...data,
      [name]: value,
    };
    setData(updatedData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('Submitted user data:', data);
    // Add any additional submit logic here
  };

  return (
    <form className={css.newsletterForm} onSubmit={handleSubmit}>
      <Text className={css.newsletterText}>Newsletter</Text>
      <input
        type="email"
        className={css.newsletterInput}
        id="contact-email"
        name="contact-email"
        autoComplete="email"
        placeholder="Enter your email address"
        value={data['contact-email']}
        onChange={handleDataChange}
        required
      />
      <button type="submit" className={css.newsletterButton}>
        Subscribe Now
      </button>
    </form>
  );
};

export default NewsletterSubscribe;
